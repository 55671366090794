body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", Arial, serif;
    min-height: 100vh;
}
.page-fill {
    width: 100%;
    height: 100vh;
}
.page-fill-middle-container {
    width: 600px;
    max-width: 100%;
    padding: 50px;
    border-radius: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.page-fill-middle-container h1 {
    margin-top: 0;
}
a {
    text-decoration: none;
    transition: 0.2s;
}
.text-align-center {
    text-align: center;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
#page-content {
    padding: 50px;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    max-width: 100wv;
    box-sizing: border-box;
}