[data-theme='dark'] {
    --accent-primary: #d57527;
    --accent-secondary: #6d05ce
}
[data-theme='dark'] .background-main {
    background-color: #1e1e1e;
}
[data-theme='dark'] .color-classic-text {
    color: #8a8a8a;
}
[data-theme='dark'] .background-gradient-accents {
    background: linear-gradient(var(--accent-primary), var(--accent-secondary));
}
[data-theme='dark'] .color-accent-a {
    color: var(--accent-primary);
}
[data-theme='dark'] .color-accent-a:hover {
    color: #ff851d;
}
[data-theme='dark'] .color-accent {
    color: var(--accent-primary);
}
.MuiTouchRipple-child {
     background-color: blue;
 }