:root {
    --accent-primary: #ff7602;
    --accent-secondary: #6d05ce
}
.background-main {
    background-color: #fff;
}
.color-classic-text {
    color: #8c8c8c;
}
.background-gradient-accents {
    background: linear-gradient(var(--accent-primary), var(--accent-secondary));
}
.color-accent-a {
    color: var(--accent-primary);
}
.color-accent-a:hover {
    color: #ff851d;
}
.color-accent {
    color: var(--accent-primary);
}
